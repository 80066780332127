import { classnames } from '@tailwindcss-classnames';

// eslint-disable-next-line import/prefer-default-export
export const anchor = classnames(
  'text-nzxt-light-grey-500',
  'font-semibold',
  'text-xs',
  'cursor-pointer',
  'hover:text-white'
);
