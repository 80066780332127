import { memo, FC } from 'react';
import { useTranslation as t } from '@utils/hooks';
import type { FooterLink } from '@framework/api/types';
import FooterLegal from '../FooterLegal';
import FooterCookieControlLink from '../FooterCookieControlLink';
import * as styles from './styles';

type Props = {
  legalLinks: FooterLink[];
};

const FooterBottomRow: FC<Props> = ({ legalLinks }) => (
  <div className={styles.outer}>
    <div className={styles.inner}>
      <div className={styles.column}>
        <div>
          <p className={styles.title}>NZXT</p>
          <p
            className={styles.copyright}
          >{`© NZXT Inc. ${new Date().getFullYear()} ${t(
            'all_rights_reserved'
          )}`}</p>
        </div>
        {legalLinks && legalLinks.map(g => <FooterLegal key={g.slug} {...g} />)}
        <FooterCookieControlLink />
      </div>
    </div>
  </div>
);

export default memo(FooterBottomRow);
