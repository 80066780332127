/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import { track } from '@amplitude/analytics-browser';
import { useHistory } from '@utils/history';
import GTM from '@utils/gtm';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import type { FooterLink } from '@framework/api/types';
import * as styles from './styles';

const FooterLegal: FC<FooterLink> = ({ title, slug, _modelApiKey }) => {
  const { last: lastPage } = useHistory();

  // track link clicks via GTM and amplitude
  const handleLinkClick = (): void => {
    const payload = {
      footerEndLink: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footerEnd',
        ...payload,
      },
    });

    const eventProperties = {
      'page source url': window.location.href,
      page_path: window.location.pathname,
      'referrer source url': lastPage,
      'nav item selected': title,
      location: 'footer',
    };

    track('nav item clicked', eventProperties);
  };

  return (
    <Link
      key={`footer-legal-link-${slug}`}
      href={getCmsLinkUrl({ contentType: _modelApiKey, slug })}
      className={styles.anchor}
      onClick={() => handleLinkClick()}
      onKeyDown={() => handleLinkClick()}
      tabIndex={0}
      role="link"
    >
      {title}
    </Link>
  );
};

export default memo(FooterLegal);
