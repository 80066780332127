import { classnames } from '@tailwindcss-classnames';

export const inner = classnames('flex');

export const anchor = classnames(
  'text-nzxt-light-grey-500',
  'font-semibold',
  'text-xs',
  'hover:text-white'
);
