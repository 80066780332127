import { classnames } from '@tailwindcss-classnames';

export const title = classnames(
  'text-sm',
  'leading-5',
  'font-semibold',
  'text-white',
  'tracking-wider',
  'uppercase'
);

export const list = classnames('mt-4', 'space-y-4');

export const link = classnames('hover:text-white', 'block');

export const textTreatment = classnames(
  'text-sm',
  'leading-6',
  'text-nzxt-light-grey-500'
);
