import { FC, memo } from 'react';
import type { FooterColumn } from '@framework/api/types';
import FooterColumnComponent from '../FooterColumn';
import * as styles from './styles';

type Props = {
  columns?: FooterColumn[];
};

const FooterLinkColumns: FC<Props> = ({ columns }) => (
  <div className={styles.outer}>
    {columns && columns.length > 0 && (
      <>
        {columns.map(column => (
          <FooterColumnComponent key={column.title} {...column} />
        ))}
      </>
    )}
  </div>
);

export default memo(FooterLinkColumns);
