import { classnames } from '@tailwindcss-classnames';

export const outer = classnames(
  'max-w-screen-2xl',
  'mx-auto',
  'pb-20',
  'lg:pb-7',
  'px-4',
  'sm:px-6',
  'lg:px-12'
);

export const inner = classnames('sm:pb-3', 'pb-3', 'md:pb-12', 'lg:pb-20');

export const utilityLinks = classnames(
  'md:flex',
  'justify-between',
  'items-center',
  'pt-8',
  'mb-4',
  'md:mb-12'
);

export const socialLinksDesktop = classnames('hidden', 'md:block');
export const socialLinksMobile = classnames('md:hidden', 'mt-4');

export const backgroundWrapper = classnames(
  'bg-nzxt-dark-grey-800',
  'flex-shrink-0'
);
