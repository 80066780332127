import { classnames } from '@tailwindcss-classnames';

export const title = classnames(
  'leading-4',
  'text-sm',
  'font-semibold',
  'text-white',
  'tracking-wider',
  'uppercase',
  'py-1'
);

export const list = classnames('mt-2', 'pr-12');

export const link = classnames('hover:text-white', 'block', 'py-2');

export const textTreatment = classnames(
  'text-sm',
  'leading-6',
  'text-nzxt-light-grey-500'
);

export const accordionButton = classnames(
  'text-left',
  'w-full',
  'flex',
  'justify-between',
  'items-center',
  'border-b',
  'pt-2',
  'pb-4',
  'w-full'
);

const activeList = classnames('h-full', 'opacity-100', 'visible');
const inactiveList = classnames('h-0', 'opacity-0', 'hidden');

export const getActiveList = (isActive: boolean): string =>
  classnames(
    {
      [activeList]: isActive,
      [inactiveList]: !isActive,
    },
    'mt-2',
    'pr-12'
  );

const activeAction = classnames('rotate-180');

export const svg = (isActive: boolean): string =>
  classnames(
    {
      [activeAction]: isActive,
    },
    'w-6',
    'stroke-current',
    'text-white',
    'transition-transform',
    'duration-200',
    'transform'
  );
