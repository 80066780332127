import { FC } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useHistory } from '@utils/history';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

const FooterCookieControlLink: FC = () => {
  const { last: lastPage } = useHistory();

  const handleClick = (): void => {
    if (
      typeof window !== 'undefined' &&
      typeof window.CookieControl !== 'undefined'
    ) {
      window.CookieControl.open();

      const eventProperties = {
        'page source url': window.location.href,
        page_path: window.location.pathname,
        'referrer source url': lastPage,
        'nav item selected': 'Manage Cookie Preferences',
        location: 'footer',
      };

      track('nav item clicked', eventProperties);
    }
  };

  return (
    <span
      className={styles.anchor}
      onClick={() => handleClick()}
      onKeyDown={() => handleClick()}
      role="button"
      tabIndex={0}
    >
      {t('manage_cookie_preferences')}
    </span>
  );
};

export default FooterCookieControlLink;
