import { memo, FC } from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconYouTube,
  IconTwitch,
  IconTikTok,
  IconReddit,
  IconDiscord,
} from '@nzxt/react-icons';
import { useHistory } from '@utils/history';
import GTM from '@utils/gtm';
import type { SocialLink } from '@framework/api/types';
import * as styles from './styles';

const ICON_MAP = {
  facebook: <IconFacebook className={styles.iconSvg} />,
  instagram: <IconInstagram className={styles.iconSvg} />,
  twitter: <IconTwitter className={styles.iconSvg} />,
  youtube: <IconYouTube className={styles.iconSvg} />,
  twitch: <IconTwitch className={styles.iconSvg} />,
  tiktok: <IconTikTok className={styles.iconSvg} />,
  reddit: <IconReddit className={styles.iconSvg} />,
  discord: <IconDiscord className={styles.iconSvg} />,
};

type Props = SocialLink & { idSuffix?: string };

const FooterSocialIcon: FC<Props> = ({
  text,
  link,
  iconComponent,
  idSuffix,
}) => {
  const { last: lastPage } = useHistory();

  // track link clicks via GA
  const handleLinkClick = (): void => {
    const payload = {
      socNetwork: text,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footerSocial',
        ...payload,
      },
    });

    const eventProperties = {
      'page source url': window.location.href,
      page_path: window.location.pathname,
      'referrer source url': lastPage,
      'nav item selected': text,
      location: 'footer',
    };

    track('nav item clicked', eventProperties);
  };

  return (
    <a
      href={link}
      id={`social-link-${text}${idSuffix ? `-${idSuffix}` : ''}`}
      className={styles.iconA}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => handleLinkClick()}
      onKeyDown={() => handleLinkClick()}
      tabIndex={0}
    >
      <span className={styles.iconSpan}>{text}</span>
      {ICON_MAP[iconComponent.toLowerCase()]}
    </a>
  );
};

export default memo(FooterSocialIcon);
