/* eslint-disable react/no-array-index-key */
import { memo, FC } from 'react';
import type { SocialLink } from '@framework/api/types';

import FooterSocialIcon from './FooterSocialIcon';
import * as styles from './styles';

type Props = {
  socialLinks: SocialLink[];
  idSuffix?: string;
};

const FooterSocial: FC<Props> = ({ socialLinks, idSuffix }) => (
  <div className={styles.outer}>
    <div className={styles.inner}>
      {socialLinks.map((link, i) => (
        <FooterSocialIcon
          key={`social-link-${link.text}-${i}`}
          {...link}
          idSuffix={idSuffix}
        />
      ))}
    </div>
  </div>
);

export default memo(FooterSocial);
