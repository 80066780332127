/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC, useState } from 'react';
import Link from 'next/link';
import { track } from '@amplitude/analytics-browser';
import { IconChevronDown } from '@nzxt/react-icons';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { useHistory } from '@utils/history';
import GTM from '@utils/gtm';
import slugify from '@utils/slugify';
import type { FooterLink } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  id: string;
  title: string;
  name?: string;
  links: FooterLink[];
};

const FooterMobileAccordion: FC<Props> = ({ id, title, links }) => {
  const [active, setActiveState] = useState(false);

  const toggleAccordion = (): void => {
    setActiveState(!active);
  };

  const { last: lastPage } = useHistory();

  // track link clicks via GTM and amplitude
  const handleLinkClick = (name: string): void => {
    const payload = {
      footerlinkName: name,
      footerlinkRefer: window.location.href,
      footerSection: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footermenuClick',
        ...payload,
      },
    });

    const eventProperties = {
      'page source url': window.location.href,
      page_path: window.location.pathname,
      'referrer source url': lastPage,
      'nav item selected': name,
      location: 'footer',
    };

    track('nav item clicked', eventProperties);
  };

  return (
    <div id={id}>
      <button
        type="button"
        className={styles.accordionButton}
        onClick={toggleAccordion}
      >
        <h4 className={styles.title}>{title}</h4>

        <IconChevronDown className={styles.svg(active)} />
      </button>
      <ul className={styles.getActiveList(active)}>
        {links.map(link =>
          link._modelApiKey === 'external_link' ? (
            <li
              key={`footer-link-${slugify(link.title)}`}
              className={styles.textTreatment}
            >
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                id={`footer-link-${slugify(link.title)}`}
                className={styles.link}
                onClick={() => handleLinkClick(link.title)}
                onKeyDown={() => handleLinkClick(link.title)}
              >
                {link.title}
              </a>
            </li>
          ) : (
            <li
              key={`footer-link-${link.slug}`}
              className={styles.textTreatment}
            >
              <Link
                href={getCmsLinkUrl({
                  contentType: link._modelApiKey,
                  pageType: link.pageType,
                  slug: link.slug,
                  parentSlug: link?.parentCategory?.slug,
                })}
                id={`footer-link-${link.slug}`}
                className={styles.link}
                onClick={() => handleLinkClick(link.title || link.name)}
                onKeyDown={() => handleLinkClick(link.title || link.name)}
              >
                {link.title || link.name}
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default memo(FooterMobileAccordion);
