import { classnames } from 'tailwindcss-classnames';

export const outer = classnames(
  'grid',
  'gap-2',
  'md:gap-8',
  'sm:gap-2',
  'sm:grid-cols-1',
  'md:grid-cols-3',
  'xl:grid-cols-6'
);

export default outer;
