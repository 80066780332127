/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import Link from 'next/link';
import { track } from '@amplitude/analytics-browser';
import { useMedia } from '@utils/hooks';
import { useHistory } from '@utils/history';
import type { FooterColumn } from '@framework/api/types';
import GTM from '@utils/gtm';
import slugify from '@utils/slugify';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import FooterMobileAccordion from '../FooterMobileAccordion/FooterMobileAccordion';
import * as styles from './styles';

const FooterColumnComponent: FC<FooterColumn> = ({ title, links }) => {
  const isSmall = useMedia('sm');
  const id = `footer-column-${slugify(title)}`;

  const { last: lastPage } = useHistory();

  // track link clicks via GTM and amplitude
  const handleLinkClick = (name: string): void => {
    const payload = {
      footerlinkName: name,
      footerlinkRefer: window.location.href,
      footerSection: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footermenuClick',
        ...payload,
      },
    });

    const eventProperties = {
      'page source url': window.location.href,
      page_path: window.location.pathname,
      'referrer source url': lastPage,
      'nav item selected': name,
      location: 'footer',
    };

    track('nav item clicked', eventProperties);
  };

  return (
    <div id={id}>
      {isSmall ? (
        <FooterMobileAccordion id={id} title={title} links={links} />
      ) : (
        <>
          <h4 className={styles.title} id={`footer-col-${id}`}>
            {title}
          </h4>
          <ul className={styles.list}>
            {links.map(link =>
              link._modelApiKey === 'external_link' ? (
                <li
                  key={`footer-link-${slugify(link.title)}`}
                  className={styles.textTreatment}
                >
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={`footer-link-${slugify(link.title)}`}
                    className={styles.link}
                    onClick={() => handleLinkClick(link.title)}
                    onKeyDown={() => handleLinkClick(link.title)}
                    tabIndex={0}
                  >
                    {link.title}
                  </a>
                </li>
              ) : (
                <li
                  key={`footer-link-${link.slug}`}
                  className={styles.textTreatment}
                >
                  <Link
                    href={getCmsLinkUrl({
                      contentType: link._modelApiKey,
                      pageType: link.pageType,
                      slug: link.slug,
                      parentSlug: link?.parentCategory?.slug,
                    })}
                    id={`footer-link-${link.slug}`}
                    className={styles.link}
                    onClick={() => handleLinkClick(link.title || link.name)}
                    onKeyDown={() => handleLinkClick(link.title || link.name)}
                    role="link"
                    tabIndex={0}
                  >
                    {link.title || link.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default memo(FooterColumnComponent);
