import { classnames } from '@tailwindcss-classnames';

export const outer = classnames(
  'mt-4',
  'xl:mt-0',
  'w-full',
  'items-start',
  'lg:pt-20',
  'md:pt-12',
  'sm:pt-3',
  'pt-3'
);
export const inner = classnames(
  'grid',
  'gap-4',
  'grid-cols-1',
  'lg:grid-cols-3'
);

export const column = classnames(
  'flex',
  'lg:items-end',
  'col-span-1',
  'lg:col-span-2',
  'flex-col',
  'lg:flex-row',
  'text-center',
  'lg:text-left',
  'md:space-x-4'
);

export const title = classnames('text-white', 'font-bold', 'text-xs');

export const copyright = classnames(
  'text-nzxt-light-grey-500',
  'text-xs',
  'mr-0',
  'md:mr-6'
);
