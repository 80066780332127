import { classnames } from '@tailwindcss-classnames';

export const outer = classnames(
  'md:flex',
  'md:items-center',
  'md:justify-between'
);

export const inner = classnames(
  'flex',
  'flex-wrap',
  'md:flex-no-wrap',
  'justify-center',
  'sm:justify-center',
  'md:justify-center',
  'lg:justify-start'
);

export const text = classnames(
  'mt-8',
  'text-base',
  'leading-6',
  'text-gray-400',
  'md:mt-0'
);

export const iconA = classnames(
  'text-gray-400',
  'hover:text-nzxt-volt-200',
  'p-3'
);

export const iconSpan = classnames('sr-only');

export const iconSvg = classnames('h-6', 'w-6');
